import { Layout, Row, Col, Typography, Divider } from "antd";
import { Redirect } from "react-router-dom";
import Navbar from "../Navbar/Presentational";
import CustomFeature from "./CustomFeature/Presentational";
import Feature from "../shared/Features/Presentational";
import IllustrationHero from "../../../assets/illustrations/landing page/home-hero-2.png";
import ConnectHero from "../../../assets/illustrations/landing page/home-connect.png";
import Footer from "../Footer/Presentational";
import YourBenefits from "../shared/YourBenefits/Presentational";
import Copy from "../shared/Copy/Presentational";
import { googleAndLinkedInTracker } from "#helpers/general";
import { useEffect } from "react";
import { useHistory } from "react-router";

const { Title } = Typography;

const featureButtons = [
  {
    name: "Book your demo today",
    href: "https://go.leva.pe/contact-us",
    left: true,
    onClick: googleAndLinkedInTracker,
    type: "ghost"
  }
  // { name: 'I am a startup raising funds', to: '/startup', left: false },
];

const connectFeatureButtons = [
  {
    name: "Get in touch",
    href: "https://go.leva.pe/contact-us",
    type: "v3",
    onClick: googleAndLinkedInTracker,
    className: "connect-btn"
  }
];

export default function Home(props) {
  const { userConfirmationLevel } = props;

  console.log(userConfirmationLevel, "SADSAQAD");

  useEffect(() => {
    if (!userConfirmationLevel) {
      window.location.href = "https://website.leva.pe";
    }
  }, []);

  return (
    <Layout className="teal-background">
      {/*<Row>
        <Col className="shared-col-2" span={24}>
          <Row type="flex" justify="center">
            <Col span={20}>
              <Navbar />
              <Feature
                textLeft={true}
                buttons={featureButtons}
                showButtons={true}
                titleClassName="feature-l2-title2"
                header="Create and manage syndicates online with ease"
                text="LEVA is the definitive solution for streamlined syndicate and co-investment management. Our platform offers the fastest, easiest, and most cost-effective way to create and manage investment vehicles, empowering you to efficiently handle multiple co-investment opportunities.  "
                icon={IllustrationHero}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" justify="center">
        <Col span={24}>
          <Row type="flex" justify="center">
            <Col span={20}>
              <div className="home-logolist-wrapper">
                <Title className="l1-title">
                  Optimize your investment operations
                </Title>
                <br />
                <YourBenefits className="your-benefits-wrapper" />
              </div>

              <Feature
                buttons={connectFeatureButtons}
                showButtons={true}
                titleClassName="feature-l2-title2-dark"
                textClassName="feature-text-dark"
                header="Connect with LEVA "
                text={
                  <>
                    <p>
                      At LEVA, we are dedicated to building a dynamic community
                      of investors, simplifying the syndication process, and
                      making it more accessible, compliant, and relevant for
                      everyone. Our platform connects investors from around the
                      globe, fostering collaboration and enabling you to expand
                      your network and elevate your investment operations.
                    </p>
                    <p>
                      By joining the LEVA community, you gain access to a
                      powerful ecosystem where syndicated investments are
                      streamlined, compliant, and within reach.
                    </p>
                  </>
                }
                icon={ConnectHero}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" className="footer">
        <Footer />
        <Copy />
      </Row>*/}
    </Layout>
  );
}
