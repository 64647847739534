import { Route, Switch } from "react-router-dom";

import CL from "#constants/confirmationLevel";
import { roles } from "#constants/roles";
import AuthRoute from "./components/AuthRoute/Container";
import PublicRoute from "./components/PublicRoute/Container";

// import Home from "./components/Home/Container";
// import Home from "./components/Landing/Home/Container";

import DealManager from "./components/Landing/DealManager/Presentational";
import Startup from "./components/Landing/Startup/Presentational";
import VentureSyndicate from "./components/Landing/VentureSyndicate/Presentational";
import Home from "./components/Landing/Home3/Container";
import DealManagers from "./components/Landing/NewDM/Presentational";
import Founders from "./components/Landing/NewFounder/Presentational";

import Impressum from "./components/Landing/Impressum/Presentational";

import {
  CapTable,
  Generator,
  Investment,
  DealManagement,
  IndividualDeal,
  InvestorsList,
  ContractGenerating,
  TaxReportList,
  TaxReportGenerator
} from "./components/Dashboard/";
import GenericNotFound from "./components/Shared/NotFound/GenericNotFound";
import Logout from "./components/Logout/Presentational";
import {
  MainView as PortfolioMainView,
  IndividualView as PortfolioIndividual
} from "./components/Portfolio/Views/";
import Team from "./components/Landing/Team/Presentational";
import ResetPassword from "./components/ResetPassword/index";
import {
  Login,
  Registration,
  FounderInvitation
} from "./components/Authorization";
import {
  FounderInvitationForm,
  EntityCreationForm,
  DealCreationForm,
  TsGenerationForm,
  ShareholderAgreementForm,
  IAForm,
  SubscriptionForm,
  NotaryForm,
  BoardRegulations,
  CLATermsheetForm,
  CLAQuestionnaire,
  CLAForm,
  VotingAssembly,
  VotingSession
} from "./components/Forms";
import SharesManagement from "./components/Shares/Container";
import Onboarding from "./components/Onboarding/Container";
import { Questionnaire } from "./components/Onboarding/Views";
import { ContainerWrapper } from "./components/Shared/Modals/EntitySettings";
import PdfViewer from "./components/PDFViewer/Presentational";
import {
  EquityView,
  ConvertibleOverview,
  ClaOverview
} from "./components/Dashboard/Pages/DealManagement/Views";
import Stakeholders from "./components/Stakeholders/Container";
import { Management } from "./components/CLA";
import InvitationAcceptance from "./components/InvitationAcceptance/Presentational";
import FoundersCaptable from "./components/FoundersCaptable/Container";
import EntityAccount from "./components/EntityAccount/Container";
import CookiesSettings from "./components/CookiesSettings/Presentational";
import VotingArchive from "./components/Dashboard/Pages/VotingArchive/Container";
import VotingDetails from "./components/Dashboard/Pages/VotingDetails/Container";
import Phone from "./components/Shared/Phone/Presentational";
import Kyc from "./components/KYC/Container";

const {
  INVESTOR,
  FOUNDER,
  AM_INVESTOR,
  ASSET_MANAGER,
  DIRECTOR,
  EMPLOYEE,
  LAWYER
} = roles;

export default (
  <Switch>
    <PublicRoute exact path="/" component={Home} />
    <PublicRoute exact path="/login" component={Login} />
    <PublicRoute exact path="/registration" component={Registration} />
    <PublicRoute exact path="/founder_invite" component={FounderInvitation} />
    {/* <PublicRoute exact path="/deal-manager" component={DealManager} /> */}
    {/* <PublicRoute exact path="/startup" component={Startup} /> */}
    {/* <PublicRoute exact path="/legal" component={Legal} /> */}
    {/*<PublicRoute exact path="/deal-managers" component={DealManagers} />*/}
    {/* <PublicRoute exact path="/founders" component={Founders} /> */}
    <PublicRoute exact path="/impressum" component={Impressum} />
    {/* <PublicRoute exact path="/venture-syndicate" component={VentureSyndicate} /> */}
    <PublicRoute exact path="/team" component={Team} />
    <PublicRoute exact path="/phone" component={Phone} />
    <AuthRoute
      exact
      path="/portfolio"
      confirmationLevel={CL.CONFIRMED}
      component={PortfolioMainView}
    />
    <AuthRoute
      exact
      path="/portfolio/:id"
      confirmationLevel={CL.CONFIRMED}
      component={PortfolioIndividual}
    />
    <AuthRoute
      exact
      path="/kyc"
      confirmationLevel={CL.CONFIRMED}
      rolesAllowed={[FOUNDER, INVESTOR, AM_INVESTOR]}
      component={Kyc}
    />
    {/* DASHBOARD ROUTES */}
    <AuthRoute
      exact
      path={["/dashboard/generator"]}
      component={Generator}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/dashboard/cap-table"
      component={CapTable}
      rolesAllowed={[
        FOUNDER,
        INVESTOR,
        ASSET_MANAGER,
        AM_INVESTOR,
        DIRECTOR,
        EMPLOYEE
      ]}
    />
    <AuthRoute
      exact
      path="/dashboard/investments"
      component={Investment}
      rolesAllowed={[INVESTOR, AM_INVESTOR]}
    />
    <AuthRoute
      exact
      path={["/dashboard/deal_management"]}
      component={DealManagement}
      rolesAllowed={[FOUNDER, ASSET_MANAGER]}
      key="deal_management"
    />
    <AuthRoute
      exact
      path={["/dashboard"]}
      component={DealManagement}
      rolesAllowed={[ASSET_MANAGER]}
      key="dashboard"
    />

    <AuthRoute
      exact
      path="/dashboard/deal_management/:dealId"
      component={IndividualDeal}
      rolesAllowed={[FOUNDER, ASSET_MANAGER, AM_INVESTOR, INVESTOR]}
    />
    <AuthRoute
      exact
      path="/dashboard/equity/:dealId"
      component={EquityView}
      rolesAllowed={[FOUNDER, ASSET_MANAGER, AM_INVESTOR, INVESTOR]}
    />

    <AuthRoute
      exact
      path="/dashboard/investors"
      component={InvestorsList}
      rolesAllowed={[FOUNDER, ASSET_MANAGER]}
    />

    <AuthRoute
      exact
      path="/dashboard/voting"
      component={VotingArchive}
      rolesAllowed={[ASSET_MANAGER, AM_INVESTOR]}
    />

    <AuthRoute
      exact
      path="/dashboard/voting-details/:cuid"
      component={VotingDetails}
      rolesAllowed={[ASSET_MANAGER]}
    />

    <AuthRoute
      exact
      path="/generate/contract/:cuid"
      component={ContractGenerating}
      rolesAllowed={[FOUNDER, ASSET_MANAGER]}
    />

    <AuthRoute
      exact
      path="/tax_reports"
      component={TaxReportList}
      rolesAllowed={[ASSET_MANAGER, AM_INVESTOR, INVESTOR]}
    />

    <AuthRoute
      exact
      path="/tax_reports/:year"
      component={TaxReportGenerator}
      rolesAllowed={[ASSET_MANAGER, AM_INVESTOR, INVESTOR]}
    />

    {/* END OF DASHBOARD ROUTES */}
    <AuthRoute
      exact
      path="/stakeholders"
      component={Stakeholders}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/stakeholders/:id"
      component={Stakeholders}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/create/founder_invitation"
      component={FounderInvitationForm}
      rolesAllowed={[FOUNDER, ASSET_MANAGER]}
    />
    <AuthRoute
      exact
      path={["/create/entity", "/edit/entity/:entityId"]}
      component={EntityCreationForm}
      rolesAllowed={[FOUNDER, ASSET_MANAGER]}
    />
    <AuthRoute
      exact
      path={["/create/deal", "/edit/deal/:ioId"]}
      component={DealCreationForm}
      rolesAllowed={[FOUNDER, ASSET_MANAGER]}
    />
    <AuthRoute
      exact
      path={["/create/termsheet", "/create/termsheet/:id"]}
      component={TsGenerationForm}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/create/board-regulation/:id"
      component={BoardRegulations}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/create/cla-questionnaire/:id"
      component={CLAQuestionnaire}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/create/sha/:dealId"
      component={ShareholderAgreementForm}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path={["/create/ia", "/create/ia/:id"]}
      component={IAForm}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/create/subscription/:id"
      component={SubscriptionForm}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path={["/create/cla_termsheet", "/create/cla_termsheet/:id"]}
      component={CLATermsheetForm}
    />
    <AuthRoute
      exact
      path={["/create/cla", "/create/cla/:id"]}
      component={CLAForm}
    />
    <AuthRoute
      exact
      path="/create/notary/:id"
      component={NotaryForm}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/create/voting_assembly/:ioId"
      component={VotingAssembly}
      rolesAllowed={[ASSET_MANAGER]}
    />
    <AuthRoute
      exact
      path="/voting/:id"
      component={VotingSession}
      rolesAllowed={[ASSET_MANAGER, AM_INVESTOR]}
    />
    <AuthRoute
      exact
      path="/settings"
      component={ContainerWrapper}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/cla/management"
      component={Management}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/cla/management/:id"
      component={Management}
      rolesAllowed={[FOUNDER]}
    />

    <AuthRoute
      exact
      path="/shares-management"
      component={SharesManagement}
      rolesAllowed={[FOUNDER]}
    />

    <AuthRoute
      exact
      path="/shares-management/:id"
      component={SharesManagement}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path={["/cla-ts/overview", "/cla-ts/overview/:id"]}
      component={ConvertibleOverview}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path={["/cla/overview", "/cla/overview/:id"]}
      component={ClaOverview}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/invitations"
      component={InvitationAcceptance}
      rolesAllowed={[FOUNDER, ASSET_MANAGER, INVESTOR, AM_INVESTOR]}
    />
    <AuthRoute
      exact
      path="/founder_captable"
      component={FoundersCaptable}
      rolesAllowed={[FOUNDER]}
    />
    <AuthRoute
      exact
      path="/account-settings"
      component={EntityAccount}
      rolesAllowed={[FOUNDER, ASSET_MANAGER, AM_INVESTOR, INVESTOR]}
    />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/doc_viewer" component={PdfViewer} />
    <Route exact path="/cookies" component={CookiesSettings} />
    <Route exact path="/onboarding" component={Onboarding} />
    <Route exact path="/onboarding/questionnaire" component={Questionnaire} />
    <Route exact path="/reset_password" component={ResetPassword} />
    <Route exact component={GenericNotFound} />
  </Switch>
);
