import { entityTypes } from "#constants/entity";
import { roles } from "#constants/roles";
import { connect } from "react-redux";
import Presentational from "./Presentational";

const mapStateToProps = state => {
  const {
    _data: { confirmationLevel: userConfirmationLevel } = {}
  } = state.users;

  return {
    userConfirmationLevel
  };
};

export default connect(mapStateToProps)(Presentational);
